






import { Vue, Component } from 'nuxt-property-decorator'
import PostsList from '~/components/post/PostsList.vue'
import { productStore } from '~/store'

@Component({
  async fetch({ payload }) {
    if (payload) {
      await productStore.setPotstsGenerate(payload)
    } else {
      await productStore.initPosts({
        slug: '',
        date: '',
        category: '',
      })
    }
  },
  components: {
    PostsList,
  },
})
export default class Index extends Vue {}
